import React from "react";
import {
  StyledPressScroller,
  StyledPressScrollerItems,
  StyledPressScrollerItem,
} from "./style.jsx";
import Link from "../Link/Link";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PressScroller = ({ items, ...props }) => {
  return (
    <StyledGridRow isFullMobile>
      <Scroller>
        <StyledPressScroller>
          <StyledPressScrollerItems>
            {items.map((item, index) => {
              const imageData = props.allImages.find(e => e.path === item.image);

              return (
                <StyledPressScrollerItem key={index}>
                  <Link to={item.url}>
                    {imageData && (
                      <GatsbyImage
                        image={getImage(imageData)}
                        alt={imageData.alt}
                      />
                    )}
                  </Link>
                </StyledPressScrollerItem>
              );},
            )}
          </StyledPressScrollerItems>
        </StyledPressScroller>
      </Scroller>
    </StyledGridRow>
  );
};

export default PressScroller;
